/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 118.75%; }

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.5;
  color: #444; }

img {
  max-width: 100%;
  margin: 0 auto; }

a, div, p, h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #444;
  line-height: 1.5; }

h1 {
  font-weight: 400;
  font-size: 32px;
  margin: 20px 0 30px; }

.container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto; }

p {
  line-height: 1.5;
  margin-bottom: 15px; }

a {
  color: #4e7ec7;
  text-decoration: underline;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500; }

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.main {
  flex-grow: 1; }

.no-underline {
  text-decoration: none; }

.header__container {
  background-image: url(img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  height: 75px;
  background-position: center 85%; }
  .header__container--voting-record {
    background-image: linear-gradient(transparent 65%, rgba(0, 0, 0, 0.8) 100%), url(img/city-hall.jpg); }
  .header__container--nearpeer {
    background-image: linear-gradient(transparent 65%, rgba(0, 0, 0, 0.8) 100%), url(img/nearpeer-banner.jpg); }
  .header__container--photo-site {
    background-image: linear-gradient(transparent 65%, rgba(0, 0, 0, 0.8) 100%), url(img/yaejin.jpg); }
  @media (min-width: 800px) {
    .header__container {
      height: 170px; } }

.header__text {
  color: white;
  text-shadow: 0px 1px 1px black;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 22px; }

.header__icon-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: -40px; }
  .header__icon-container::before, .header__icon-container::after {
    content: "";
    display: block;
    position: relative;
    height: 40px;
    width: 100%;
    width: calc(50% - 40px);
    background: white; }

.header__icon {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: absolute;
  left: calc(50% - 40px);
  bottom: 0px; }
  .header__icon::after {
    box-sizing: border-box;
    content: "";
    height: 80px;
    width: 80px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    box-shadow: 0 40px 0 15px white;
    border-radius: 50%;
    border: 2px solid white;
    background: url(img/logo.png);
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat; }

p {
  margin-bottom: 15px;
  line-height: 1.5; }

.about__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  margin: 30px auto 60px;
  flex-direction: column; }
  @media screen and (min-width: 600px) {
    .about__container {
      flex-direction: row; } }
  @media screen and (min-width: 1100px) {
    .about__container {
      max-width: 800px; } }

.about__img {
  width: 150px;
  border-radius: 50%;
  margin: 0 auto; }

.about__img-container {
  display: none;
  margin-right: 30px; }
  @media (min-width: 800px) {
    .about__img-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: 0; } }

.about__text-title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 15px; }
  @media screen and (min-width: 600px) {
    .about__text-title {
      font-size: 3.26rem; } }

.about__text-body {
  margin-bottom: 1rem; }

.cards__container {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 900px;
  margin: 0 auto; }
  @media (min-width: 600px) {
    .cards__container {
      justify-content: space-between;
      flex-direction: row; } }

.card__container {
  text-decoration: none;
  background: white;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px -5px black; }
  .card__container:hover {
    cursor: pointer;
    box-shadow: 0px 3px 10px -3px black; }
  .card__container::after {
    content: ''; }
  @media (min-width: 600px) {
    .card__container {
      width: calc(33% - 5px);
      margin-bottom: 0; } }

.card__img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  padding: 30px 0; }

.card__text {
  flex-grow: 1;
  background: white;
  padding: 5px 10px;
  box-shadow: 0px 3px 5px -5px black;
  position: relative;
  border-radius: 0 0 10px 10px;
  padding: 30px 20px;
  text-align: center;
  background: rgba(105, 183, 224, 0.15); }

.card__title {
  font-weight: bold;
  font-size: 1.125em;
  padding: 0 0 10px; }

.section__title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px; }

.section {
  background: linear-gradient(rgba(145, 163, 193, 0.31), rgba(255, 192, 203, 0.29));
  background: linear-gradient(rgba(75, 76, 225, 0.2), rgba(237, 87, 114, 0.3));
  width: 100vw;
  padding: 30px 0;
  margin-left: calc(-1 * (100vw - 100%)/2); }

.footer {
  background: #333;
  padding: 25px 0; }
  .footer:not(.homepage) {
    margin-top: 100px; }
  .footer__link {
    color: #8398da;
    color: #a7bfe3; }
  .footer__text {
    text-align: center;
    color: #eee;
    margin: 0;
    padding: 0; }

.footer-logo {
  width: 50px;
  margin: 0 10px; }
  .footer-logo path {
    fill: #eee;
    width: 30px; }

.instagram-logo {
  width: 30px; }

.page__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.page__body-container {
  margin: 0 auto;
  width: 80%;
  max-width: 900px;
  flex: 1; }

.page__body {
  color: #333;
  font-size: 18px; }

.page__title {
  font-size: 40px;
  padding: 20px 0;
  font-weight: 800; }

@keyframes pulse {
  from {
    opacity: 1; }
  to {
    opacity: .3; } }

body {
  padding: 0;
  margin: 0; }
  body .nowplaying__container {
    display: none; }
  body .nowlistening {
    margin: 15px 0 5px;
    font-size: 13px;
    font-weight: 500;
    animation: pulse 1s infinite alternate ease-in-out; }
  body .nowplayingcard {
    width: 200px;
    margin: 0 auto;
    font-size: 13px; }
    body .nowplayingcard.hidden {
      display: none; }
    body .nowplayingcard .nowplayingcontainer-inner {
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      display: inline-block;
      -webkit-border-top-left-radius: 5px;
      -moz-border-top-left-radius: 5px;
      -ms-border-top-left-radius: 5px;
      -o-border-top-left-radius: 5px;
      border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-bottom-left-radius: 5px;
      -ms-border-bottom-left-radius: 5px;
      -o-border-bottom-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-radius: 5px;
      overflow: hidden; }
      body .nowplayingcard .nowplayingcontainer-inner img#trackart {
        max-width: 30%;
        float: left;
        left: 0; }
      body .nowplayingcard .nowplayingcontainer-inner .trackInfo {
        width: 70%;
        float: left;
        display: block; }
        body .nowplayingcard .nowplayingcontainer-inner .trackInfo a, body .nowplayingcard .nowplayingcontainer-inner .trackInfo p {
          max-width: 90%;
          display: block;
          font-size: 14px;
          text-align: left;
          text-decoration: none;
          vertical-align: middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 0; }
          body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(odd), body .nowplayingcard .nowplayingcontainer-inner .trackInfo p:nth-child(odd) {
            color: black;
            font-weight: bold;
            vertical-align: middle;
            line-height: 15px;
            letter-spacing: 0.2px;
            padding: 10% 0 0 5%; }
            body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(odd) img, body .nowplayingcard .nowplayingcontainer-inner .trackInfo p:nth-child(odd) img {
              width: 15px;
              height: 15px;
              vertical-align: middle;
              margin: -2% 3px 0 0; }
          body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(even), body .nowplayingcard .nowplayingcontainer-inner .trackInfo p:nth-child(even) {
            color: gray;
            font-size: 12px;
            letter-spacing: 0.1px;
            padding: 5% 0 0 5%; }
            body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(even) img, body .nowplayingcard .nowplayingcontainer-inner .trackInfo p:nth-child(even) img {
              width: 15px;
              height: 15px;
              vertical-align: middle;
              margin: -2% 3px 0 0; }
